import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

import Corn from "../svg/doctor-15.svg"

const Header = ({ siteTitle }) => (
  <header className="text-gray-700 body-font bg-white">
    <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
      <Link
        to="/"
        className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
      >
        <img className="w-10 h-10 mr-5" src={Corn} alt="Dotaznik" />
        <h1>{siteTitle}</h1>
      </Link>
      <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
        DOTAZNÍK SPOKOJNOSTI  -  22. a 23. apríl 2022
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
